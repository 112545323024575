import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import ReleaseNotes from './components/ReleaseNotes'; // Import the new component
import Testing from './components/Testing';

function App() {
  return (
    <Router>
      <div className="bg-white">
        <Header />
        <Routes>
          <Route path="/" element={<HeroSection />} />
          <Route path="/release-notes" element={<ReleaseNotes />} /> {/* Add the route */}
          <Route path="/testing" element={<Testing />} /> {/* Add the route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;