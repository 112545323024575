import React, { useState } from 'react';
import axios from 'axios';
import LottieAnimation from './LottieAnimation'; // Adjust the path as needed

function CustomHubspotForm() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const sendSlackNotification = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTION_URL}`, {
        email
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        console.log('Message sent to Slack successfully');
      } else {
        console.error('Error sending message to Slack', response.statusText);
      }
    } catch (error) {
      console.error('Error sending message to Slack', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const hubspotFormId = process.env.REACT_APP_HUBSPOT_FORM_ID;
    const hubspotPortalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID;

    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormId}`;

    const payload = {
      fields: [
        { name: 'email', value: email },
      ],
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        setSubmitted(true);
        sendSlackNotification();
      } else {
        console.error('Form submission error', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting form', error);
    }
  };

  return (
    <div className="mt-6 flex items-center gap-x-4">
      <LottieAnimation visible={!submitted} />
      <form onSubmit={handleSubmit} className="flex w-full max-w-md gap-x-4">
        <div className="flex w-full gap-x-4 p-2 rounded-md">
          <label htmlFor="email-address" className="sr-only">Email address</label>
          <input
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            required
            className={`min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 ${submitted ? 'italic' : ''}`}
            placeholder="email"
            value={email}
            onChange={handleChange}
            disabled={submitted}
          />
          <button
            type="submit"
            className={`flex-none rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${submitted ? 'bg-[#F4BBFF]' : 'bg-indigo-500'}`}
            disabled={submitted}
          >
            {submitted ? "More to come!" : "Update Me!"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default CustomHubspotForm;