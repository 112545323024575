const navigation = [
    { name: 'Product', href: '#' },
    { name: 'Features', href: '#' },
    { name: 'Marketplace', href: '#' },
    { name: 'Company', href: '#' },
  ];
  
  function NavigationMenu() {
    return (
      <>
        {navigation.map((item) => (
          <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-300">
            {item.name}
          </a>
        ))}
      </>
    );
  }
  
  export default NavigationMenu;